import { render, staticRenderFns } from "./index.vue?vue&type=template&id=7ceb43e9&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=7ceb43e9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ceb43e9",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BijbellabMonogram: require('/app/components/Bijbellab/Monogram.vue').default,BijbellabLogo: require('/app/components/Bijbellab/Logo.vue').default,BijbellabCard: require('/app/components/Bijbellab/Card.vue').default,BijbellabManifestStack: require('/app/components/Bijbellab/ManifestStack.vue').default,BijbellabManifestRow: require('/app/components/Bijbellab/ManifestRow.vue').default})
