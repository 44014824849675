// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/bijbellab/dots-grid.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hero.eo-hero[data-v-7ceb43e9]{overflow:hidden}.hero.eo-hero[data-v-7ceb43e9] .eo-hero__figure{background-color:#e9e9e9}.hero.eo-hero[data-v-7ceb43e9] .eo-hero__figure:before{background:50%/max(100vw,100vh) url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");bottom:0;content:\"\";display:block;height:100%;left:0;opacity:.05;position:absolute;right:0;top:0;width:100%}.hero.eo-hero[data-v-7ceb43e9] .eo-hero__figure:after{background-color:transparent;background-image:radial-gradient(circle at 20% -110%,#da1aff,transparent 60%),radial-gradient(ellipse at 70% 150%,#b0eaed,transparent 50%);bottom:0;height:100%;left:0;right:0;top:0;width:100%}.hero.eo-hero[data-v-7ceb43e9] .eo-hero__content{color:#454545;overflow:visible}.hero .monogram[data-v-7ceb43e9]{animation-delay:.5s;animation-duration:4s;animation-fill-mode:backwards;animation-name:monogram-7ceb43e9;bottom:0;color:#c100e5;height:100vh;left:-10%;-webkit-mask-image:linear-gradient(rgba(0,0,0,.4),rgba(0,0,0,.2) 30%,transparent 85%);mask-image:linear-gradient(rgba(0,0,0,.4),rgba(0,0,0,.2) 30%,transparent 85%);position:absolute;right:0;rotate:-16deg;width:100%;z-index:0}@keyframes monogram-7ceb43e9{0%{-webkit-clip-path:inset(0 0 100% 0);clip-path:inset(0 0 100% 0);opacity:0}to{-webkit-clip-path:inset(0);clip-path:inset(0);opacity:1}}.eo-button.scroll-arrow[data-v-7ceb43e9]{margin:-44px auto 0;padding:0;translate:0 50%;width:44px;z-index:5}.partnership[data-v-7ceb43e9]{background:linear-gradient(#fff,#daf5f6);margin-bottom:-50px;overflow:hidden}.partnership .monogram[data-v-7ceb43e9]{color:#34c8d1;margin-bottom:-75%;-webkit-mask-image:linear-gradient(rgba(0,0,0,.4),rgba(0,0,0,.2) 40%,transparent 85%);mask-image:linear-gradient(rgba(0,0,0,.4),rgba(0,0,0,.2) 40%,transparent 85%);rotate:-16deg}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
