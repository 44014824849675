
export default {
  props: {
    experiment: {
      type: Object,
      default: () => {},
    },
    unfinished: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data () {
    return {
      isSharingPossible: true,
      showHyperlink: false,
      isCopySuccesfull: false,
      shareText: 'Probeer dit experiment',
      shareUrl: null,
    }
  },
  mounted () {
    this.isSharingPossible = navigator.share || navigator.clipboard
    this.shareUrl =  `${this.$config.baseUrl}/bijbel-lab/${this.experiment.slug}`
  },
  methods: {
    async shareExperiment () {
      try {
        await navigator.share({ 
          text: this.shareText, 
          url: this.shareUrl,
        })
      } catch (error) {
        this.showHyperlink = !this.showHyperlink
      }
    },
    async copyHyperlink () {
      try {
        await navigator.clipboard.writeText(this.shareUrl)
        this.isCopySuccesfull = true
      } catch (error) {
        try {
          this.$refs.hyperlink?.$el?.select()
          document.execCommand('copy')
          this.isCopySuccesfull = true
        } catch (error) {
          this.isCopySuccesfull = false
        }
      }
    },
  },
}
