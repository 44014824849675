
import { metaDataMixin, eoRendererMixin } from '~/mixins'

export default {
  mixins: [
    metaDataMixin,
    eoRendererMixin,
  ],
  layout: 'bijbellab',
  async asyncData({ $api, $config, store, route: { path }, error }) {
    const slug = 'bijbel-lab'
    const storyId = 'ae9641f5-4e90-4297-b423-57260860a0bc' // content bundle
    const conceptsStoryId = '77c467fd-0de2-448b-997e-cbdaaca912ea' // content bundle

    // Get page
    const {
      data: {
        items: page = [],
      } = {},
    } = await $api.pages.getOneBySlug(slug, {
      domain: $config.preprEnvironmentDomainEo,
    })
    if (!page || !page.length) {
      error({
        context: `Couldn't create '${slug}' page.`,
        message: '(404) Pagina niet gevonden',
        severity: 'info',
        statusCode: 404,
      })
      return false
    }

    const { data: { items: story } = {} } = await $api.stories.getOneBySlug(slug, {
    domain: $config.preprEnvironmentDomainEo,
    })

    if (!story || !story[0]) {
      error({
        statusCode: 404,
        message: '(404) Rubriek niet gevonden',
        context: `Couldn't find story: ${slug}.`,
        severity: 'warning',
      })
      return false
    }
    store.dispatch('pianoAnalytics/setProject', $config.trackingFieldHome)

    // Get experiments (pages)
    const {
      data: {
        items: experiments = [],
      } = {},
    } = await $api.pages.getAll({
      story: storyId,
      domain: $config.preprEnvironmentDomainEo,
      limit: 100,
    })
    experiments.sort((a, b) => a.likes > b.likes ? -1 : 1)

    // Get concept experiments (pages)
    const {
      data: {
        items: experimentConcepts = [],
      } = {},
    } = await $api.pages.getAll({
      story: conceptsStoryId,
      domain: $config.preprEnvironmentDomainEo,
      limit: 100,
    })
    experimentConcepts.sort((a, b) => a.likes > b.likes ? -1 : 1)

    // Get backstories (teasers)
    const {
      data: {
        items: backstories = [],
      } = {},
    } = await $api.teasers.getAll({
      story: storyId,
      domain: $config.preprEnvironmentDomainEo,
      limit: 100,
    })

    store.dispatch('breadcrumbs/set', {
      path,
      eoRoot: true,
      floating: false,
      width: 6,
      items: [
        {
          to: '/bijbel-lab',
          label: 'Bijbel_Lab',
        },
      ],
    })

    return {
      ...page[0],
      experiments,
      experimentConcepts,
      backstories,
    }
  },
  data () {
    return {
      experiments: [],
      experimentConcepts: [],
      backstories: [],
      seo: {
        title: null,
        description: null,
      },
      social: {
        image: null,
      },
    }
  },
  methods: {
    scrollToExperiments () {
      this.$refs.experiments?.$el?.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    },
  },
}
