import { render, staticRenderFns } from "./ManifestRow.vue?vue&type=template&id=1677bc63&scoped=true"
var script = {}
import style0 from "./ManifestRow.vue?vue&type=style&index=0&id=1677bc63&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1677bc63",
  null
  
)

export default component.exports